import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { AlertsContext, IAlert, AlertType } from "../../../../components/molecules/Alerts/AlertsContext";
import { getAxiosRequestConfig } from "../../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../../services/Constants/Constants";
import { useAuth } from "../../../useAuth";
import { QueryCacheKey } from "../../QueryCacheKey";

interface IResponse {}

const getMutationQuery = (token: string | null, clientId: string, reason: string) => {
    const postRequest = async (): Promise<IResponse> => {
        const config = getAxiosRequestConfig(token);
        const request = axios.post(
            `${Constants.ApiRoot}/admin/banned-client`,
            {
                clientId,
                reason,
            },
            config
        );

        const { data } = await request;
        return data;
    };
    return postRequest;
};

export const usePostBannedClient = (clientId: string, reason: string, onSuccess: (response: IResponse) => void) => {
    const { authToken } = useAuth();
    const { pushAlert } = useContext(AlertsContext);
    const queryClient = useQueryClient();

    const mutation = useMutation(getMutationQuery(authToken, clientId, reason), {
        onMutate: () => {},
        onSuccess: (data) => {
            queryClient.invalidateQueries([QueryCacheKey.BannedClients], { exact: false });
            onSuccess(data);
        },
        onError: (error, _variables, _context) => {
            const e = error as AxiosError<any>;
            const submitError = JSON.stringify(e.response?.data?.error);
            const alert: IAlert = {
                type: AlertType.Error,
                message: submitError,
                timeout: Constants.AlertModalTimeoutMs,
            };
            pushAlert?.(alert);
        },
    });
    return { mutation };
};
